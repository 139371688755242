<template>
<div>
  <date-picker
  :disabled-date="disabledDates"
  v-model="date"
  type="date"
  :range="type === 'Return Date' || type === 'hotels'"
  :placeholder="type === 'Return Date' ? 'Trip Dates' : (type === 'hotels' ? 'Checking Dates' : type)"
  format="MMM DD YYYY"
  value-type="YYYY-MM-DD"
  editable
  range-separator=" | "
  :time-picker-options="{
    start: '00:00',
    step: '00:30',
    end: '23:30',
  }"
  >
    <template v-slot:icon-calendar>
      <v-icon>mdi-calendar</v-icon>
    </template>
    <template v-slot:icon-clear>
      <v-icon>mdi-close</v-icon>
    </template>
  </date-picker>
</div>
</template>

<script>
export default {
  props: ['type'],
  data () {
    return {
      // date: '',
      date: null,
      menu: false,
      today: new Date().toISOString().substr(0, 10),
      minDate: null,
      disable: false
    }
  },
  watch: {
    date (value) {
      switch (this.type) {
        case 'Departure Date':
          this.$store.dispatch('setDates', { data: value, type: 'departure1' })
          break
        case 'Return Date':
          this.$store.dispatch('setDates', { data: value[0], type: 'departure1' })
          this.$store.dispatch('setDates', { data: value[1], type: 'arrival' })
          break
        case 'Second Departure Date':
          this.$store.dispatch('setDates', { data: value, type: 'departure2' })
          break
        case 'Third Departure Date':
          this.$store.dispatch('setDates', { data: value, type: 'departure3' })
          break
        case 'hotels':
          this.$store.dispatch('setHotelCheckInDate', value[0])
          this.$store.dispatch('setHotelCheckOutDate', value[1])
          break
        default:
          break
      }
    }
  },
  methods: {
    disabledDates (date) {
      let today = new Date()
      switch (this.type) {
        case 'Second Departure Date':
          today = new Date(this.$store.state.flightDates.departureDates[0])
          break
        case 'Third Departure Date':
          today = new Date(this.$store.state.flightDates.departureDates[1])
          break
      }

      return date < today || date > new Date(today.setFullYear(today.getFullYear() + 1))
    },
    setDates () {
      if (this.$store.state.flightType === 'round') this.date = [new Date(), new Date(new Date().setDate(new Date().getDate() + 1))]
      else this.date = new Date()
      let prevDate
      switch (this.type) {
        case 'Departure Date':
          this.date = this.$store.state.flightDates.departureDates[0]
          break
        case 'Return Date':
          this.date = [this.$store.state.flightDates.departureDates[0] || new Date(), this.$store.state.flightDates.arrivalDate || new Date()]
          break
        case 'Second Departure Date':
          prevDate = new Date(this.$store.state.flightDates.departureDates[0])
          this.date = this.$store.state.flightDates.departureDates[1] || prevDate.setDate(prevDate.getDate() + 1)
          break
        case 'Third Departure Date':
          prevDate = new Date(this.$store.state.flightDates.departureDates[1])
          this.date = this.$store.state.flightDates.departureDates[2] || prevDate.setDate(prevDate.getDate() + 1)
          break
        case 'hotels':
          this.date = [this.$store.state.hotelSearch.checkInDate || new Date(), this.$store.state.hotelSearch.checkOutDate || new Date()]
          break
        default:
          break
      }
    }
  },
  created () {
    this.setDates()
  }
}
</script>

<style>
  .mx-datepicker {
    width: 100% !important;
    height: 56px !important;
    margin-bottom: 30px;
  }
  .mx-input {
    height: 56px !important;
    /* width: 100%; */
    /* height: 56px; */
    color: #222 !important;
    background-color: #fff !important;
    border: 1px solid #9e9e9e !important;
    border-radius: 8px !important;
    /* margin-bottom: 30px; */
  }
  .mx-input:focus {
    border: 2px solid #325AAF !important;
  }

  .pickers {
    height: 70px;
  }
  @media screen and (max-width: 520px) {
    .pickers {
      height: 50px;
    }
  }
</style>
